
<template>
  <div class="flex min-h-screen font-sans">
    <div class="flex flex-col items-center justify-start flex-1 px-4 py-12 sm:px-6 lg:flex-none lg:w-[800px] lg:px-20 xl:px-24 ">
      <div class="w-full lg:w-[475px]">
        <div class="w-24 ">
          <router-link
            to="/"
            class="w-24 h-24"
          >
            <img
              class="w-24 h-24"
              src="@/assets/logo.svg"
              alt="Logo"
            >
          </router-link>
        </div>

        <h2 class="text-3xl font-extrabold text-primary">
          Cookies
        </h2>
        <div class="mt-5 space-y-5 text-lg leading-7 text-white">
          <p>Et id excepteur ipsum quis qui cupidatat consectetur laborum aliquip elit reprehenderit ea non. Sint velit quis amet in tempor adipisicing consequat mollit. Est sit commodo do pariatur adipisicing sint eu mollit. Laborum ut nostrud aute ullamco nulla sunt sunt.</p>
          <p>Tempor enim occaecat commodo cillum non veniam. Commodo velit laborum non est laboris id aliquip cillum id ut. Nostrud Lorem adipisicing ullamco ut ea eu esse non. Ut cillum do esse duis ex quis minim aliqua sit do officia laborum. Sunt proident non velit velit nostrud eiusmod non. Cupidatat est velit non velit amet. Et minim veniam incididunt do ullamco commodo cupidatat fugiat cupidatat.</p>
          <p>
            Culpa quis laborum consectetur dolore eiusmod qui commodo irure eu id. Sint irure ea incididunt do et sunt reprehenderit eu occaecat labore. Aliquip aute qui esse nostrud dolor tempor ut deserunt do cillum proident. Deserunt incididunt officia ullamco elit enim labore.

            Labore nulla amet fugiat aute est pariatur tempor occaecat Lorem anim commodo sit anim excepteur. Commodo adipisicing nisi aute quis et est duis culpa mollit eu. Elit quis dolor incididunt deserunt dolore sit mollit ut esse voluptate sint enim. Et culpa deserunt fugiat quis magna cupidatat magna.

            Ipsum ut aute irure ea sunt deserunt sunt veniam Lorem elit dolore duis proident velit. Consectetur velit consectetur ullamco esse sunt deserunt laboris dolore id ex non magna est laboris. Magna dolore dolore ut et. Sunt incididunt tempor minim sunt nostrud consequat amet. Proident nostrud id ex cupidatat commodo adipisicing nulla. Minim labore dolore nostrud velit amet Lorem quis duis ea dolore deserunt. Anim exercitation voluptate laborum laborum veniam aute laboris cupidatat.
          </p>
        </div>
      </div>
    </div>
    <div class="relative flex-1 hidden w-0 lg:block">
      <img
        class="absolute inset-0 object-cover w-full h-full"
        src="@/assets/img/backgrounds/black-temple.jpg"
        alt=""
      >
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  metaInfo () {
    return {
      title: 'Cookie Policy',
      meta: [
        { property: 'og:title', vmid: 'og:title', content: 'Cookie Policy' },
        { property: 'og:url', vmid: 'og:url', content: `${this.baseUrl}${this.$route.path}` }
      ]
    }
  }
}
</script>
